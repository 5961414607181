import Image from 'next/image';
import Link from 'next/link';
import { navigation } from '@components/config/Footer';
import { social } from '@components/config/SocialLinks';
import { appstorelinks } from '../config/AppStoreLinks';
import { company } from '@components/config/Company';
import { MapPinIcon } from '@heroicons/react/20/solid';
import { Phone3 } from '@utils/constants';

const Footer = () => {
  return (
    <div className="px-10 bg-secondary">
      <div className="grid grid-cols-1 gap-3 py-10 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        <div>
          <h1 className="my-2 font-bold text-center text-darkGray md:text-left md:my-0">
            SERVICES
          </h1>
          {navigation.services.map((item, index) => (
            <div
              key={index}
              className="md:w-[170px] hover:bg-lightYellow text-darkGray md:text-left text-center mb-2 cursor-pointer"
            >
              <Link href={item.href}>{item.name}</Link>
            </div>
          ))}
          <div className="hidden xl:block">
            <div className="flex pt-5 font-bold text-darkGray ">
              <div>
                <MapPinIcon className="w-6 h-6" aria-hidden="true" />
              </div>
              <div>How to find us</div>
            </div>
            <div className="p-3 text-darkGray">
              {company.map((item) => (
                <div key={item.name}>
                  {item.address.building}&nbsp;
                  {item.address.street} <br />
                  {item.address.town} <br /> {item.address.postcode} <br />
                  Tel: {item.tel_number}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div>
            <h1 className="my-2 font-bold text-center text-darkGray md:text-left md:my-0">
              DRIVERS
            </h1>
            {navigation.drivers.map((item, index) => (
              <div
                key={index}
                className="md:w-[170px] md:text-left text-center hover:bg-lightYellow text-darkGray mb-2 cursor-pointer"
              >
                <Link href={item.href}>
                  <a target={item._blank}>{item.name}</a>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div>
            <h1 className="my-2 font-bold text-center text-darkGray md:text-left md:my-0">
              Blogs
            </h1>

            <div></div>
          </div>
        </div>
        <div>
          <div>
            <h1 className="my-2 font-bold text-center text-darkGray md:text-left md:my-0">
              COMPANY
            </h1>
            {navigation.company.map((item, index) => (
              <div
                key={index}
                className="md:w-[270px] hover:bg-lightYellow md:text-left text-center md:my-0 my-2 text-darkGray mb-2 cursor-pointer"
              >
                <Link href={item.href}>{item.name}</Link>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center gap-2 md:justify-start">
            {social.map((item) => (
              <a
                target={'_blank'}
                key={item.name}
                href={item.href}
                className="text-white/80 p-3 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="w-6 h-6" aria-hidden="true" />
              </a>
            ))}
          </div>
        </div>
        <div>
          <h1 className="w-[250px] m-auto text-center md:my-0 my-2 text-darkGray">
            WE ACCEPT ALL MAJOR CARDS
          </h1>
          <div className="flex flex-col items-center justify-center my-5 font-bold text-darkGray">
            {appstorelinks.map((item, index) => (
              <div
                key={index}
                className="relative h-[50px] w-[200px] my-2 curser-pointer"
              >
                <Link href={item.href} passHref>
                  <a target="_blank" rel="noopener noreferrer">
                    <Image
                      src={item.Imgsrc}
                      alt={item.alt}
                      height={56}
                      width={198}
                    />
                  </a>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mb-5 font-light text-center text-darkGray max-auto">
        {company.map((item) => (
          <p key={item.name}>{item.trading}</p>
        ))}
      </div>
      <div className="block md:hidden">
        <div className="flex items-center justify-center pt-5 font-bold text-darkGray">
          <div>
            <MapPinIcon className="w-6 h-6" aria-hidden="true" />
          </div>
          <div>How to find us</div>
        </div>
        <div className="p-3 text-center text-darkGray">
          {company.map((item) => (
            <div key={item.name}>
              {item.address.building}&nbsp;
              {item.address.street} <br />
              {item.address.town} <br /> {item.address.postcode} <br />
              Tel: {item.tel_number}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center justify-between py-1 border-t items-centermd:flex-row md:flex-row border-white/20 ">
        {company.map((item) => (
          <div key={item.name} className="my-5 text-darkGray font-sm">
            © {item.Copyright_year} {item.name}
          </div>
        ))}
        <Link href={'https://xiots.io/'}>
          <a target={'_blank'}>
            <Image
              alt="Xiots"
              src={'/assets/images/xiots-logo.png'}
              width={128}
              height={54}
            />
          </a>
        </Link>
      </div>
    </div>
  );
};
export default Footer;
